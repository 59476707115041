


































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiSelffetchShopList,
    apiSelffetchShopStatus,
    apiSelffetchShopDel
} from '@/api/application/selffetch'
import { PageMode } from '@/utils/type'
import { RequestPaging } from '@/utils/util'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'

@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData
    }
})
export default class SelffetchShop extends Vue {
    /** S Data **/
    apiSelffetchShopList = apiSelffetchShopList
    // 表单数据
    form = {
        name: '', //名称
        status: '' //角色id
    }
    pager: RequestPaging = new RequestPaging()
    /** E Data **/

    /** S Methods **/
    // 搜索
    search() {
        this.pager.page = 1
        this.getSelffetchShopList()
    }

    // 重置搜索
    resetSearch() {
        Object.keys(this.form).map(key => {
            this.$set(this.form, key, '')
        })
        this.getSelffetchShopList()
    }

    // 获取列表数据
    getSelffetchShopList() {
        // 请求管理员列表
        this.pager
            .request({
                callback: apiSelffetchShopList,
                params: this.form
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载!')
            })
    }

    // 添加
    addSelffetchShop() {
        this.$router.push({
            path: '/selffetch/selffetch_shop_edit',
            query: {
                mode: PageMode.ADD
            }
        })
    }

    // 删除
    onSelffetchShopDelete(data: any) {
        apiSelffetchShopDel({ id: data.id }).then(() => {
            // 删除成功就请求新列表
            this.getSelffetchShopList()
        })
    }

    // 编辑
    goSelffetchShopEdit(data: any) {
        this.$router.push({
            path: '/selffetch/selffetch_shop_edit',
            query: {
                mode: PageMode.EDIT,
                id: data.id
            }
        })
    }

    // 更改状态
    changeSwitchStatus(value: 0 | 1, data: any) {
        apiSelffetchShopStatus({
            id: data.id,
            status: value
        }).catch(err => {
            this.getSelffetchShopList()
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getSelffetchShopList()
    }

    /** E Life Cycle **/
}
